import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  TacticalCommunicationSigns,
  TacticalSigns,
} from "../../types/tacticalSigns";
import { ReactComponent as Connection } from "../../images/tacticalSigns/connection.svg";
import { ReactComponent as KatSL } from "../../images/tacticalSigns/katsl.svg";
import { ReactComponent as TEL } from "../../images/tacticalSigns/tel.svg";
import { ReactComponent as EL } from "../../images/tacticalSigns/el.svg";
import { ReactComponent as EAL } from "../../images/tacticalSigns/eal.svg";
import { ReactComponent as UEAL } from "../../images/tacticalSigns/ueal.svg";
import { ReactComponent as LtS } from "../../images/tacticalSigns/ltS.svg";
import { ReactComponent as ILS } from "../../images/tacticalSigns/ils.svg";
import { ReactComponent as RLS } from "../../images/tacticalSigns/rls.svg";
import { ReactComponent as BR } from "../../images/tacticalSigns/br.svg";
import { ReactComponent as BR_FS } from "../../images/tacticalSigns/br_fs.svg";
import { ReactComponent as BR_M } from "../../images/tacticalSigns/br_m.svg";
import { ReactComponent as M } from "../../images/tacticalSigns/m.svg";
import { ReactComponent as L } from "../../images/tacticalSigns/l.svg";
import { ReactComponent as Log } from "../../images/tacticalSigns/log.svg";

import { ReactComponent as TmoLetters } from "../../images/tacticalSigns/tmoLetters.svg";
import { ReactComponent as DmoLetters } from "../../images/tacticalSigns/dmoLetters.svg";
import { ReactComponent as FaxLetters } from "../../images/tacticalSigns/faxLetters.svg";
import { ReactComponent as MobileLetters } from "../../images/tacticalSigns/mobileLetters.svg";
import { ReactComponent as TelLetters } from "../../images/tacticalSigns/telLetters.svg";

const getIconByName = (
  name?: string,
  style?: React.CSSProperties
): ReactJSXElement | undefined => {
  if (!name) return undefined;
  switch (name) {
    case TacticalSigns.KatastrophenSchutzLeitung:
      return <KatSL style={style} />;
    case TacticalSigns.TechnischeEinsatzLeitung:
      return <TEL style={style} />;
    case TacticalSigns.EinsatzLeitung:
      return <EL style={style} />;
    case TacticalSigns.EinsatzAbschnittsLeitung:
      return <EAL style={style} />;
    case TacticalSigns.UnterEinsatzAbschnittsLeitung:
      return <UEAL style={style} />;
    case TacticalSigns.Leitstelle:
      return <LtS style={style} />;
    case TacticalSigns.IntegrierteLeitstelle:
      return <ILS style={style} />;
    case TacticalSigns.RettungsLeitstelle:
      return <RLS style={style} />;
    case TacticalSigns.Bereitstellungsraum:
      return <BR style={style} />;
    case TacticalSigns.BereitstellungsraumFuehrungsstelle:
      return <BR_FS style={style} />;
    case TacticalSigns.BereitstellungsraumMeldekopf:
      return <BR_M style={style} />;
    case TacticalSigns.Meldekopf:
      return <M style={style} />;
    case TacticalSigns.Lotsenstelle:
      return <L style={style} />;
    case TacticalSigns.Logistikstuetzpunkt:
      return <Log style={style} />;

    case TacticalCommunicationSigns.Connection:
      return <Connection style={style} />;

    case TacticalCommunicationSigns.ConnectionTmoLetters:
      return <TmoLetters style={style} />;
    case TacticalCommunicationSigns.ConnectionDmoLetters:
      return <DmoLetters style={style} />;
    case TacticalCommunicationSigns.ConnectionFaxLetters:
      return <FaxLetters style={style} />;
    case TacticalCommunicationSigns.ConnectionTelLetters:
      return <TelLetters style={style} />;
    case TacticalCommunicationSigns.ConnectionMobileLetters:
      return <MobileLetters style={style} />;

    default:
      throw new Error("invalid tactical sign");
  }
};

interface Props {
  name?: string;
  style?: React.CSSProperties;
}

export default function TacticalSign({ name, style }: Props) {
  const Icon = getIconByName(name, style);
  return Icon ?? <></>;
}
