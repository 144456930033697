import React from "react";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    grid: {
        alignItems: "center",
    },
    gridHeader: {
        alignItems: "center",
        fontSize: 18,
        fontWeight: 600,
    },
}));

interface Props {
    children: React.ReactNode;
    header?: React.ReactNode;
}

export default function FormSection({ children, header }: Props) {
    const { classes } = useStyles();
    return (
        <>
            {header && (
                <Grid container spacing={0} className={classes.gridHeader}>
                    {header}
                </Grid>
            )}
            <Grid container spacing={0} className={classes.grid}>
                {children}
            </Grid>
        </>
    );
}
