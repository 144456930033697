import { Card } from "@mui/material";
import { OperationalSectionLight as OperationalSectionTypeLight } from "../../../types/operationalSection";
import { ForwardedRef } from "react";
import SectionInfo from "./SectionInfo";
import React from "react";

interface Props {
  operationalSection: OperationalSectionTypeLight;
  widthPerElement: number;
  disabled: boolean;
  hasChildren: boolean;
  remove: (event: React.MouseEvent<HTMLButtonElement>) => void;
  edit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeIsCollapsed: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SectionCard = React.forwardRef(
  (
    {
      operationalSection,
      hasChildren,
      remove,
      edit,
      widthPerElement,
      onChangeIsCollapsed,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Card
        ref={ref}
        id={operationalSection.id}
        variant="outlined"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 5,
          minHeight: 30,
          width: widthPerElement,
          maxWidth: 200,
          marginBottom: hasChildren ? 25 : 10,
        }}
      >
        <SectionInfo
          widthPerElement={widthPerElement}
          operationalSection={operationalSection}
          onChangeIsCollapsed={onChangeIsCollapsed}
          onEdit={edit}
          onRemove={remove}
        />
      </Card>
    );
  }
);
