import { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import MissionPlanningLight from "./pages/office/missionPlanning/MissionPlanningLight";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#e60005",
    },
    secondary: {
      main: "#002d55",
    },
    warning: {
      main: "#f5770c",
    },
    background: {
      default: "#ffffff",
      paper: "#f8f8f8",
    },
    text: {
      primary: "#333333",
      secondary: "#cccccc",
    },
  },
});

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

export default function App() {
  return (
    <Suspense fallback="loading">
      <div className="App">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <MissionPlanningLight />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </Suspense>
  );
}
