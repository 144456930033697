export enum TacticalSigns {
  KatastrophenSchutzLeitung = "KatSL",
  TechnischeEinsatzLeitung = "TEL",
  EinsatzLeitung = "EL",
  EinsatzAbschnittsLeitung = "EAL",
  UnterEinsatzAbschnittsLeitung = "UEAL",
  Leitstelle = "LtS",
  IntegrierteLeitstelle = "ILS",
  RettungsLeitstelle = "RLS",
  Bereitstellungsraum = "BR",
  BereitstellungsraumFuehrungsstelle = "BR_FS",
  BereitstellungsraumMeldekopf = "BR_M",
  Meldekopf = "M",
  Lotsenstelle = "L",
  Logistikstuetzpunkt = "Log",
}

export enum TacticalCommunicationSigns {
  Connection = "CONNECT",
  ConnectionDmo = "CONNECT_DMO",
  ConnectionDmoLetters = "CONNECT_DMO_LETTERS",
  ConnectionTmo = "CONNECT_TMO",
  ConnectionTmoLetters = "CONNECT_TMO_LETTERS",
  ConnectionFax = "CONNECT_FAX",
  ConnectionFaxLetters = "CONNECT_FAX_LETTERS",
  ConnectionTel = "CONNECT_TEL",
  ConnectionTelLetters = "CONNECT_TEL_LETTERS",
  ConnectionMobile = "CONNECT_MOBILE",
  ConnectionMobileLetters = "CONNECT_MOBILE_LETTERS",
}
