import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { TacticalSigns } from "../../types/tacticalSigns";
import TacticalSign from "../icons/TacticalSign";

interface Props {
  onChange: (value?: TacticalSigns) => void;
  value?: TacticalSigns;
  style?: React.CSSProperties;
}

interface TacticalSignInputOption {
  name: string;
  type: TacticalSigns;
}

const values: TacticalSignInputOption[] = [
  {
    name: "Katastrophenschutzleitung",
    type: TacticalSigns.KatastrophenSchutzLeitung,
  },
  {
    name: "Technische Einsatzleitung",
    type: TacticalSigns.TechnischeEinsatzLeitung,
  },
  { name: "Einsatzleitung", type: TacticalSigns.EinsatzLeitung },
  {
    name: "EinsatzAbschnittsLeitung",
    type: TacticalSigns.EinsatzAbschnittsLeitung,
  },
  {
    name: "Untereinsatzabschnittsleitung",
    type: TacticalSigns.UnterEinsatzAbschnittsLeitung,
  },
  { name: "Leitstelle", type: TacticalSigns.Leitstelle },
  { name: "Integrierteleitstelle", type: TacticalSigns.IntegrierteLeitstelle },
  { name: "Rettungsleitstelle", type: TacticalSigns.RettungsLeitstelle },
  { name: "Bereitstellungsraum", type: TacticalSigns.Bereitstellungsraum },
  {
    name: "Bereitstellungsraum mit Führungsstelle",
    type: TacticalSigns.BereitstellungsraumFuehrungsstelle,
  },
  {
    name: "Bereitstellungsraum mit Meldekopf",
    type: TacticalSigns.BereitstellungsraumMeldekopf,
  },
  { name: "Meldekopf", type: TacticalSigns.Meldekopf },
  { name: "Lotsenstelle", type: TacticalSigns.Lotsenstelle },
  { name: "Logistikstützpunkt", type: TacticalSigns.Logistikstuetzpunkt },
];

export default function TacticalSignInput({ onChange, value, style }: Props) {
  const onChangeSign = (event: SelectChangeEvent<TacticalSigns>) => {
    event.stopPropagation();
    const newValue = !event.target.value
      ? undefined
      : (event.target.value as TacticalSigns);
    onChange(newValue);
  };
  return (
    <Select
      size="small"
      id="carTypeSelect"
      value={value}
      onChange={onChangeSign}
      style={{ ...style }}
      renderValue={(value: unknown) => {
        const selected = values.find((x) => x.type === value);
        return selected ? (
          <div style={{ height: 23, display: "flex", alignItems: "center" }}>
            <TacticalSign
              name={selected.type}
              style={{ width: 23, height: 23, marginRight: 10 }}
            />
            <span>{selected.name}</span>
          </div>
        ) : (
          <div style={{ height: 23, display: "flex", alignItems: "center" }}>
            <div style={{ width: 23, height: 23, marginRight: 10 }}></div>
            <span></span>
          </div>
        );
      }}
    >
      <MenuItem value={""} style={{ height: 50 }}>
        <div></div>
        <span>nicht gesetzt</span>
      </MenuItem>
      {values.map((x) => (
        <MenuItem value={x.type} style={{ height: 50 }}>
          <TacticalSign
            name={x.type}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          <span>{x.name}</span>
        </MenuItem>
      ))}
    </Select>
  );
}
