import React from "react";
import { Grid } from "@mui/material";
import { GridSize } from "@mui/material/Grid/Grid";
import { Skeleton } from '@mui/material';

interface Props {
    label?: string;
    isLoading?: boolean;
    lableWidth?: GridSize;
    inputWidth?: GridSize;
    children: React.ReactNode;
}

export default function FormInput({
    label,
    isLoading,
    children,
    inputWidth,
    lableWidth,
}: Props) {
    return (
        <>
            {label && (
                <Grid item xs={12} sm={lableWidth ?? 3}>
                    {isLoading ? <Skeleton width={`65%`} /> : label}
                </Grid>
            )}
            <Grid item xs={12} sm={inputWidth ?? 9}>
                {isLoading ? (
                    <Skeleton width={`${Math.random() * 100}%`} />
                ) : (
                    children
                )}
            </Grid>
        </>
    );
}
