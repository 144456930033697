import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { OperationalSectionsLight } from "../../../components/missionPlanning/light/OperationalSectionsLight";

export default function MissionPlanningLight() {
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <OperationalSectionsLight />
    </DndProvider>
  );
}
