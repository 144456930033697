import { useDrag, useDrop } from "react-dnd";
import { OperationalSectionLight as OperationalSectionTypeLight } from "../../../types/operationalSection";
import Xarrow from "react-xarrows";
import { useRef } from "react";
import { CommunicationChannel } from "./CommunicationChannel";
import { SectionCard } from "./SectionCard";
import { DropDirection, getCildrenAmount } from "./OperationalSectionsLight";

interface Props {
  disabled?: boolean;
  whithSide?: boolean;
  operationalSection: OperationalSectionTypeLight;
  level: number;
  widthPerElement: number;
  updateOperationalSectionIsColapsed: (
    isColapsed: boolean,
    operationalSectionId: string
  ) => void;
  changeOperationalSectionOperationalSections: (
    isOver: boolean,
    operationalSectionId: string,
    item: OperationalSectionTypeLight,
    direction: DropDirection
  ) => void;
  removeOperationalSection: (item: OperationalSectionTypeLight) => void;
  onEdit: (item: OperationalSectionTypeLight) => void;
  isPreview?: boolean;
}

export default function OperationalSectionLight({
  operationalSection,
  level,
  widthPerElement,
  updateOperationalSectionIsColapsed,
  changeOperationalSectionOperationalSections,
  removeOperationalSection,
  onEdit,
  isPreview = false,
  disabled = false,
  whithSide = true,
}: Props) {
  const [{ isDragging }, operationalSectionDragRef] = useDrag({
    type: "operationalSection",
    item: operationalSection,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, item }, dropRef] = useDrop({
    accept: "operationalSection",
    drop: (item: OperationalSectionTypeLight) => {
      if (isOver && item.id !== operationalSection.id)
        changeOperationalSectionOperationalSections(
          isOver,
          operationalSection.id,
          item,
          DropDirection.Center
        );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      item: monitor.getItem(),
    }),
  });

  const [{ isOverRight, itemRight }, dropRefRight] = useDrop({
    accept: "operationalSection",
    drop: (itemRight: OperationalSectionTypeLight) => {
      if (isOverRight && itemRight.id !== operationalSection.id)
        changeOperationalSectionOperationalSections(
          isOverRight,
          operationalSection.id,
          itemRight,
          DropDirection.Right
        );
    },
    collect: (monitor) => ({
      isOverRight: monitor.isOver({ shallow: true }),
      itemRight: monitor.getItem(),
    }),
  });

  const [{ isOverLeft, itemLeft }, dropRefLeft] = useDrop({
    accept: "operationalSection",
    drop: (itemLeft: OperationalSectionTypeLight) => {
      if (isOverLeft && itemLeft.id !== operationalSection.id)
        changeOperationalSectionOperationalSections(
          isOverLeft,
          operationalSection.id,
          itemLeft,
          DropDirection.Left
        );
    },
    collect: (monitor) => ({
      isOverLeft: monitor.isOver({ shallow: true }),
      itemLeft: monitor.getItem(),
    }),
  });

  const onChangeIsCollapsed = (event: React.MouseEvent<HTMLButtonElement>) => {
    updateOperationalSectionIsColapsed(
      !operationalSection.isCollapsed,
      operationalSection.id
    );
  };

  const remove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    removeOperationalSection(operationalSection);
  };

  const edit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onEdit(operationalSection);
  };

  const getColor = (
    isDragging: boolean,
    isOver: boolean,
    isPreview: boolean
  ) => {
    if (isDragging) return "#f9d2c9";
    if (isOver) return "#cccccc";
    if (isPreview) return "#b3e9b9";
    return undefined;
  };

  const sectionInfoRef = useRef<HTMLDivElement>(null);
  const communicationRef = useRef<HTMLDivElement>(null);

  const childrenCount = getCildrenAmount(
    operationalSection.operationalSections
  );
  const hasChildren = operationalSection.operationalSections.count() > 0;
  const hasRight = operationalSection.right.count() > 0;
  const hasLeft = operationalSection.left.count() > 0;

  const childrenIds = operationalSection.operationalSections.map((x) => x.id);
  const childrenRightIds = operationalSection.right.map((x) => x.id);
  const childrenLeftIds = operationalSection.left.map((x) => x.id);

  return (
    <div
      ref={!disabled ? operationalSectionDragRef : undefined}
      style={{
        width: (childrenCount > 0 ? childrenCount : 1) * widthPerElement,
      }}
    >
      <div
        ref={isPreview || isDragging || disabled ? undefined : dropRef}
        style={{
          backgroundColor: getColor(isDragging, isOver, isPreview),
          borderRadius: "1%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {whithSide && (
              <div
                ref={
                  isPreview || isDragging || disabled ? undefined : dropRefLeft
                }
                style={{
                  minWidth: 15,
                  display: "flex",
                  width: "33.33%",
                  flexDirection: "column",
                  alignItems: "start",
                  paddingRight: hasLeft ? 25 : 0,
                  backgroundColor: getColor(isDragging, isOverLeft, isPreview),
                }}
              >
                {operationalSection.left.map((operationalSection, i) => (
                  <OperationalSectionLight
                    whithSide={false}
                    widthPerElement={
                      212 < widthPerElement ? 212 : widthPerElement
                    }
                    disabled={disabled}
                    updateOperationalSectionIsColapsed={
                      updateOperationalSectionIsColapsed
                    }
                    level={level + 1}
                    key={operationalSection.id}
                    operationalSection={operationalSection}
                    onEdit={onEdit}
                    changeOperationalSectionOperationalSections={
                      changeOperationalSectionOperationalSections
                    }
                    removeOperationalSection={removeOperationalSection}
                  />
                ))}
                {isOverLeft &&
                  itemLeft.id !== operationalSection.id &&
                  !childrenLeftIds.contains(itemLeft.id) && (
                    <OperationalSectionLight
                      disabled={disabled}
                      whithSide={false}
                      widthPerElement={
                        212 < widthPerElement ? 212 : widthPerElement
                      }
                      updateOperationalSectionIsColapsed={
                        updateOperationalSectionIsColapsed
                      }
                      level={level + 1}
                      key={`${itemLeft.id}_preview`}
                      operationalSection={itemLeft}
                      isPreview
                      onEdit={onEdit}
                      changeOperationalSectionOperationalSections={
                        changeOperationalSectionOperationalSections
                      }
                      removeOperationalSection={removeOperationalSection}
                    />
                  )}
              </div>
            )}
            <div>
              <SectionCard
                widthPerElement={widthPerElement - (whithSide ? 50 : 0)}
                ref={sectionInfoRef}
                operationalSection={operationalSection}
                disabled={disabled}
                remove={remove}
                edit={edit}
                onChangeIsCollapsed={onChangeIsCollapsed}
                hasChildren={hasChildren}
              />
            </div>
            {whithSide && (
              <div
                ref={
                  isPreview || isDragging || disabled ? undefined : dropRefRight
                }
                style={{
                  minWidth: 15,
                  width: "33.33%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  paddingLeft: hasRight ? 25 : 0,
                  backgroundColor: getColor(isDragging, isOverRight, isPreview),
                  borderRadius: "1%",
                }}
              >
                {operationalSection.right.map((operationalSection, i) => (
                  <OperationalSectionLight
                    widthPerElement={
                      212 < widthPerElement ? 212 : widthPerElement
                    }
                    disabled={disabled}
                    whithSide={false}
                    updateOperationalSectionIsColapsed={
                      updateOperationalSectionIsColapsed
                    }
                    level={level + 1}
                    key={operationalSection.id}
                    operationalSection={operationalSection}
                    onEdit={onEdit}
                    changeOperationalSectionOperationalSections={
                      changeOperationalSectionOperationalSections
                    }
                    removeOperationalSection={removeOperationalSection}
                  />
                ))}

                {isOverRight &&
                  itemRight.id !== operationalSection.id &&
                  !childrenRightIds.contains(itemRight.id) && (
                    <OperationalSectionLight
                      disabled={disabled}
                      whithSide={false}
                      widthPerElement={
                        212 < widthPerElement ? 212 : widthPerElement
                      }
                      updateOperationalSectionIsColapsed={
                        updateOperationalSectionIsColapsed
                      }
                      level={level + 1}
                      key={`${itemRight.id}_preview`}
                      operationalSection={itemRight}
                      isPreview
                      onEdit={onEdit}
                      changeOperationalSectionOperationalSections={
                        changeOperationalSectionOperationalSections
                      }
                      removeOperationalSection={removeOperationalSection}
                    />
                  )}
              </div>
            )}

            {operationalSection.left.map((operationalSection, i) => (
              <Xarrow
                start={sectionInfoRef}
                end={operationalSection.id}
                path="grid"
                startAnchor={"left"}
                endAnchor="right"
                strokeWidth={2}
                showHead={false}
              />
            ))}
            {operationalSection.right.map((operationalSection, i) => (
              <Xarrow
                start={sectionInfoRef}
                end={operationalSection.id}
                path="grid"
                startAnchor={"right"}
                endAnchor="left"
                strokeWidth={2}
                showHead={false}
              />
            ))}
          </div>
          <CommunicationChannel
            ref={communicationRef}
            tmo={operationalSection.radioGroupTmo}
            dmo={operationalSection.radioGroupDmo}
          />
          {hasChildren && (
            <Xarrow
              start={sectionInfoRef}
              end={communicationRef}
              path="grid"
              startAnchor="bottom"
              endAnchor="top"
              strokeWidth={2}
              showHead={false}
            />
          )}
          {!operationalSection.isCollapsed && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {operationalSection.operationalSections.map(
                (operationalSection, i) => (
                  <>
                    <OperationalSectionLight
                      widthPerElement={widthPerElement}
                      disabled={disabled}
                      updateOperationalSectionIsColapsed={
                        updateOperationalSectionIsColapsed
                      }
                      level={level + 1}
                      key={operationalSection.id}
                      operationalSection={operationalSection}
                      onEdit={onEdit}
                      changeOperationalSectionOperationalSections={
                        changeOperationalSectionOperationalSections
                      }
                      removeOperationalSection={removeOperationalSection}
                    />
                    <Xarrow
                      start={communicationRef}
                      end={operationalSection.id}
                      path="grid"
                      startAnchor={"auto"}
                      endAnchor="top"
                      strokeWidth={2}
                      showHead={false}
                    />
                  </>
                )
              )}
              {isOver &&
                item.id !== operationalSection.id &&
                !childrenIds.contains(item.id) && (
                  <OperationalSectionLight
                    disabled={disabled}
                    widthPerElement={widthPerElement}
                    updateOperationalSectionIsColapsed={
                      updateOperationalSectionIsColapsed
                    }
                    level={level + 1}
                    key={`${item.id}_preview`}
                    operationalSection={item}
                    isPreview
                    onEdit={onEdit}
                    changeOperationalSectionOperationalSections={
                      changeOperationalSectionOperationalSections
                    }
                    removeOperationalSection={removeOperationalSection}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
