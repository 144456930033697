import TacticalSign from "../../icons/TacticalSign";
import { TacticalSigns } from "../../../types/tacticalSigns";

interface Props {
  name?: TacticalSigns;
}

export default function SectionCardIcon({ name }: Props) {
  return name ? (
    <TacticalSign
      name={name}
      style={{
        width: 50,
        height: 50,
        marginRight: 10,
        marginBottom: 5,
      }}
    />
  ) : (
    <></>
  );
}
