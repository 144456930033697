import { TacticalCommunicationSigns } from "../../../types/tacticalSigns";
import TacticalSign from "../../icons/TacticalSign";

interface Props {
  iconName?: TacticalCommunicationSigns;
  value?: string;
}

export default function ConnectionInfo({ iconName, value }: Props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: 0,
            height: 0,
            borderRight: "16px solid #040404",
            borderTop: "8px solid transparent",
            borderBottom: "8px solid transparent",
            position: "absolute",
          }}
        ></div>
        <div
          style={{
            width: 0,
            height: 0,
            borderRight: "14px solid #fcfc04",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            position: "absolute",
            left: 2,
          }}
        ></div>
      </div>
      <div
        style={{
          backgroundColor: "#fcfc04",
          height: 14,
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#040404",
          marginLeft: 16,
          display: "flex",
          width: 100,
        }}
      >
        <div style={{ width: 18, display: "flex" }}>
          <TacticalSign name={iconName} style={{ width: 15, height: 15 }} />
        </div>
        <span style={{ fontSize: 10 }}>{value}</span>
      </div>
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "16px solid #040404",
            borderTop: "8px solid transparent",
            borderBottom: "8px solid transparent",
            position: "absolute",
          }}
        ></div>
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "14px solid #fcfc04",
            borderTop: "7px solid transparent",
            borderBottom: "7px solid transparent",
            position: "absolute",
          }}
        ></div>
      </div>
    </div>
  );
}
