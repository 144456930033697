import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    de: {
        translation: {
            "common.start": "Beginn",
            "common.end": "Ende",
            "common.info": "Info",
            "common.operationalForces": "Einsatzkräfte",
            "common.operationalForce": "Einsatzkraft",
            "common.myUser": "Mein Benutzer",
            "common.logout": "Abmelden",
            "common.toLogin": "einloggen",
            "common.id": "Id",
            "common.name": "Name",
            "common.state": "Status",
            "common.role": "Rolle",
            "common.roles": "Rollen",
            "common.member": "Mitglied",
            "common.members": "Mitglieder",
            "common.user": "Benutzer",
            "common.users": "Benutzer",
            "common.userName": "Benutzername",
            "common.lastName": "Nachname",
            "common.firstName": "Vorname",
            "common.password": "Passwort",
            "common.newPassword": "neues Passwort",
            "common.description": "Beschreibung",
            "common.type": "Typ",
            "common.dashboard": "Dashboard",
            "common.color": "Farbe",
            "common.selectColor": "Farbe auswählen",
            "common.masterData": "Stammdaten",
            "common.settings": "Einstellungen",
            "common.welcome": "Willkommen",
            "common.monitorSettings": "Monitoreinstellungen",
            "common.administration": "Administration",
            "common.myFiles": "Meine Dateien",
            "common.size": "Größe",
            "common.country": "Land",
            "common.city": "Stadt",
            "common.postCode": "Postleitzahl",
            "common.street": "Straße",
            "common.additionalAddress": "Adresszusatz",
            "common.mailAddress": "Email-Adresse",
            "common.telephoneNumber": "Telefonnummer",
            "common.customers": "Mandanten",
            "common.date": "Datum",
            "common.title": "Titel",
            "common.documents": "Dokumente",
            "common.creator": "Erfasser",
            "common.dopAFileOrClick":
                "Ziehen Sie ein Bild hierher und legen Sie es ab oder klicken Sie",
            "common.pagination": "{{from}} - {{to}} von {{count}}",
            "common.rowsPerPage": "Zeilen pro Seite:",
            "common.previousPage": "Vorherige Seite",
            "common.nextPage": "Nächste Seite",
            "common.close": "Schließen",
            "common.cancelLabel": "Abbrechen",
            "common.notSet": "Nicht ausgewählt",
            "common.allowMonitor": "Monitor verwenden",
            "common.missionPlanning": "Einsatzplanung",
            "common.planning": "Planung",
            "common.leader": "Führungskraft",
            "common.amount": "Anzahl",
            "common.link": "Link",
            "common.linkForMonitor": "Link für die Anzeiges des Monitors",
            "common.transportRequest": "Transportanforderung",
            "common.completed": "Abgeschlossen",
            "common.open": "Offen",
            "common.diagnosis": "Diagnose",
            "common.missionNumber": "Einsatznummer",
            "common.birthday": "Geburtstag",
            "common.age": "Alter",
            "common.RMI": "Rückmeldezahl (RMI)",
            "common.note": "Notiz",
            "common.patient": "Patient",
            "common.years": "Jahre",

            "common.gender": "Geschlecht",
            "gender.male": "männlich",
            "gender.female": "weiblich",
            "gender.diverse": "divers",

            "common.transportType": "Transport-Typ",
            "transportType.cab": "Taxi",
            "transportType.rtw": "KTW",
            "transportType.ktw": "RTW",
            "transportType.rtwnef": "RTW + NEF",

            "common.sightingCategory": "SichtungsKategorie",
            "sightingCategory.SK_I": "SK I",
            "sightingCategory.SK_II": "SK II",
            "sightingCategory.SK_III": "SK III",

            "common.transportState": "Stransportstatus",
            "transportState.requested": "angefragt",
            "transportState.inProgress": "in Bearbeitung",
            "transportState.disposed": "disponiert",
            "transportState.transported": "transportiert",

            "common.transportProviderType": "Organisation",
            "transportProviderType.external": "Extern",
            "transportProviderType.internal": "Intern",

            "dates.month.0": "Januar",
            "dates.month.1": "Februar",
            "dates.month.2": "März",
            "dates.month.3": "April",
            "dates.month.4": "Mai",
            "dates.month.5": "Juni",
            "dates.month.6": "Juli",
            "dates.month.7": "August",
            "dates.month.8": "September",
            "dates.month.9": "Oktober",
            "dates.month.10": "November",
            "dates.month.11": "Dezember",

            "dates.day.0": "Montag",
            "dates.day.1": "Dienstag",
            "dates.day.2": "Mittwoch",
            "dates.day.3": "Donnerstag",
            "dates.day.4": "Freitag",
            "dates.day.5": "Samstag",
            "dates.day.6": "Sonntag",

            "dates.day.0.short": "Mo",
            "dates.day.1.short": "Di",
            "dates.day.2.short": "Mi",
            "dates.day.3.short": "Do",
            "dates.day.4.short": "Fr",
            "dates.day.5.short": "Sa",
            "dates.day.6.short": "So",

            "user.passwordRetype": "Passwort wiederholen",
            "user.deleteMessageShort":
                "Willst Du {{firstName}} {{lastName}} wirklich löschen?",
            "user.deleteMessageLong":
                "Wenn Du den Benutzer löscht, kann dieses Mitglied sich nicht mehr anmelden.",

            "customRole.deleteMessageShort":
                "Willst Du {{Name}} wirklich löschen?",
            "customRole.deleteMessageLong":
                "Wenn Du diese Rolle löschst, kannst du die Rolle keinem Benutzer mehr zuweisen.",

            "rights.masterData": "Stammdaten",
            "rights.settings": "Einstellungen",
            "rights.dashboard": "Dashboard",

            "rights.masterDataUsers": "Benutzer",
            "rights.masterDataUsersCreate": "Benutzer erstellen",
            "rights.masterDataUsersEdit": "Benutzer bearbeiten",
            "rights.masterDataUsersDelete": "Benutzer löschen",
            "rights.masterDataUsersRoles": "Rollen zuweisen",

            "rights.masterDataRoles": "Rollen",
            "rights.masterDataRolesCreate": "Rollen erstellen",
            "rights.masterDataRolesEdit": "Rollen bearbeiten",
            "rights.masterDataRolesDelete": "Rollen löschen",

            "rights.transportRequest": "Transportanforderung",
            "rights.transportRequestCreate": "Transportanforderung erstellen",
            "rights.transportRequestDispatch":
                "Transportanforderung disponieren",
            "rights.transportRequestClose": "Transportanforderung abschließen",

            "rights.missionPlanning": "Einsatzplanung ",
            "rights.missionPlanningCreate": "Einsatzplanung ",
            "rights.missionPlanningEdit": "Einsatzplanung erstellen",
            "rights.missionPlanningDelete": "Einsatzplanung bearbeiten",
            "rights.missionPlanningSendToMonitor": "Einsatzplanung löschen",
            "rights.monitorSettings": "Monitoreinstellungen ",
            "rights.monitorSettingsEdit": "Monitoreinstellungen bearbeiten",

            "action.create": "erstellen",
            "action.edit": "bearbeiten",
            "action.editAnyway": "dennoch bearbeiten",
            "action.createSomething": "{{something}} erstellen",
            "action.update": "bearbeiten",
            "action.updateSomething": "{{something}} bearbeiten",
            "action.updateStockWithMaterial":
                "{{stock}}: {{material}} bearbeiten",
            "action.addMaterialToStock":
                "Material zum Lager {{stock}} hinzufügen",
            "action.abort": "Abbrechen",
            "action.delete": "Löschen",
            "action.confirm": "Bestätigen",
            "action.save": "Speichern",
            "action.register": "Registrieren",
            "action.setPassword": "Passwort setzen",
            "action.checkIn": "einbuchen",
            "action.checkOut": "ausbuchen",
            "action.signIn": "Anmelden",
            "action.signOut": "Abmelden",
            "action.publish": "veröffentlichen",
            "action.close": "Abschließen",
            "action.doImport": "importieren",
            "action.selectDateAndTime": "Datum und Zeit wählen",
            "action.selectDate": "Datum wählen",
            "action.selectColor": "Farbe auswählen",
            "action.addPeronsByDragAndDrop":
                "+ Einsatzkräfte durch Drag und Drop hinzufügen",
            "action.addPeronByDragAndDrop":
                "+ Einsatzkraft durch Drag und Drop hinzufügen",
            "action.missingLeader": "Fehlende Führungskraft",

            "error.invalidRegisterHash":
                "Dieser Link ist veraltet und Steht nicht merht zu freien Registrierung von neune Benutzern bereit",
            "error.invalidPasswortResetHash":
                "Dieser Link ist veraltet und Steht nicht merht zum ändern eine vergessenen Passworts bereit",
            "validation.error.usernameDoesNotExists":
                "Benutzer existiert nicht",
            "validation.error.invalidPassword": "Falsches Passwort",
            "validation.error.notNull": "Das Feld darf nicht leer sein",
            "validation.error.notValidMailAddress":
                "Entspricht keiner gültigen Email-Adresse",
            "validation.error.passwordResetUserName.usernameDoesNotExists":
                "Der angegebene Benutzer existiert nicht",
            "validation.error.passwordResetUserName.notValidMailAddress":
                "Der angegebene Benutzername entspricht keiner gültigen Email-Adresse",
            "validation.error.notEmpty": "Das Feld darf nicht leer sein",
            "validation.error.duplicateLogin":
                "Dieser login wurde bereits vergeben",
            "validation.error.notSamePassword":
                "Passwörter stimmen nicht überein",
            "validation.error.userNameOrPasswordInvalid":
                "Login oder Passwort falsch",
            "validation.error.toShortPassword":
                "Das eingegebene Passwort ist zu kurz (mindestens 8 Zeichen)",
            "validation.error.NotPartOf":
                'Materiel "{{material}}" ist nicht im Lager "{{stock}}" vorhanden',
            "validation.error.NotPartOfLong":
                'Weise das Material "{{material}}" dem Lager "{{stock}}" zu. Dann kannst du diese Materialbewegung vornehmen',
            "validation.error.DuplicateMember":
                'Materiel "{{material}}" ist nicht mit mehreren Positionen im Lager "{{stock}}" vorhanden',
            "validation.error.DuplicateMemberLong":
                'Stelle Sicher, dass das Material "{{material}}" eindeutig dem Lager "{{stock}}" zugewiesen ist (es darf kein doppelter Eintrag vorhanden sein).',
            "validation.error.overlapReservations":
                "Es treten Überschneidungen mit folgenden Reservierungen auf",
            "validation.error.endCannotBeBeforeStart":
                "Das Ende darf sich nicht vor dem Beginn befinden",
            "validation.error.toLong":
                "Das Feld darf nur {{number}} Zeichen lang sein",
            "validation.error.notGreaterThan":
                "Der Wert darf nicht größer als {{number} sein",
            "validation.error.notLessThan":
                "Der Wert darf nicht kleiner als {{number} sein",
            "validation.error.notAllowedSelect":
                "Der Wert darf nicht kleiner als {{number} sein",
            "validation.error.illegalStateChange":
                "Diese Statusänderung ist nicht zulässig",

            "authorization.error.missingPermission": "Fehlende Berechtigung",

            "helpMessage.autofill": "Wird automatisch generiert",
            "helpMessage.userSelfRegisterWelcome": "Willkommen bei RedNet!",
            "helpMessage.userSelfRegister":
                'Wenn du hier gelandet bist, bist du einen Einladungslink deiner Organisation gefolgt. Über diese Seite kannst Du dich selbstständig für die Organisation "{{organisation}}" als Benutzer registrieren. Du bekommst eine von der Organisation definierte Rolle hinterlegt und kannst dich im Anschluss direkt hier im Browser oder über die App in RedNet anmelden.',
            "helpMessage.userSelfRegisterHaveFun":
                "Viel Spaß wünscht Dir dein RedNet-Team :)",
            "helpMessage.userResetPasswordWelcome": "Willkommen bei RedNet!",
            "helpMessage.userResetPasswor":
                "Wenn du hier gelandet bist, hast du wahrscheinlich dein Passwort vergessen und bist einem Passwort-Vergessen-Link aus deiner Mail gefolgt. Über diese Seite kannst Du dein Passwort neu setzen. Im Anschluss wirst du dann direkt zum Login weitergeleitet. Bitte beachte, dass der Link nur 30 Minuten nach versenden der Mail funktioniert.",
            "helpMessage.userResetPassworHaveFun":
                "Viel Spaß wünscht Dir dein RedNet-Team :)",
            "helpMessage.passwordResetSent":
                "Eine Mail mit dem Link zum zurücksetzen des Passwortes wurde an die angegebene E-Mail-Adresse versendet.",
            "helpMessage.missingRightForSignInToEvent":
                "Dir fehlt das Recht dich zu einer Veranstaltung einzubuchen",
            "helpMessage.missingRightForSignOutToEvent":
                "Dir fehlt das Recht dich aus einer Veranstaltung auszubuchen",
            "helpMessage.singnInToEventNotUntilTime":
                "Zu dem Termin kannst du dich erst ab dem {{dateTime}} Uhr einbuchen",
            "helpMessage.singnInToClosedEvent":
                "Dieser Termin ist bereits abgeschlossen, daher kannst du dich nicht mehr einbuchen",
            "helpMessage.singnInToNotPublishedEvent":
                "Zu einem unveröffentlichten Termin kannst du dich nicht einbuchen",

            "notifications.somethingcopiedToClipboard":
                "{{something}} wurde in die Zwischenablage kopiert",
            "notifications.serverValidationExceptionOccurred":
                "Validierungfehler",
            "notifications.serverFatalExceptionOccurred": "Unerwarteter Fehler",
            "notifications.serverPermissionExceptionOccurred":
                "Fehlende Berechtigungen",
            "notifications.EntrySaved": "Eintrag gespeichert",
            "notifications.SendToMonitor": "Eintrag zum Monitor geschickt",
            "notifications.CopyCreated": "Kopie erstellt",
            "notifications.transportCreated": "Neuer Transport",
            "notifications.transportUpdated": "Transport aktualisiert",
            "notifications.transportDisposed": "Transport disponiert",

            "notifications.transportCreatedByPerson":
                "{{firstName}} {{lastName}} hat eine neue Transportanfrage für den Einsatz {{missionNumber}} erstellt",
            "notifications.transportUpdatedByPerson":
                "{{firstName}} {{lastName}} hat die Transportanfrage für den Einsatz {{missionNumber}} aktualisiert",
            "notifications.transportDisposedByPerson":
                "{{firstName}} {{lastName}} hat die Transportanfrage für den Einsatz {{missionNumber}} disponiert",
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "de",

    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
