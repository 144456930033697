import React, { ForwardedRef } from "react";
import { TacticalCommunicationSigns } from "../../../types/tacticalSigns";
import ConnectionInfo from "./ConnectionInfo";

interface Props {
  tmo?: string;
  dmo?: string;
}

export const CommunicationChannel = React.forwardRef(
  ({ tmo, dmo }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const getCommunicationWay = () => {
      if ((tmo?.length ?? 0) > 0)
        return TacticalCommunicationSigns.ConnectionTmoLetters;
      if ((dmo?.length ?? 0) > 0)
        return TacticalCommunicationSigns.ConnectionDmoLetters;
      return undefined;
    };

    const name = (tmo?.length ?? 0) > 0 ? tmo : dmo;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
          paddingInline: 5,
        }}
      >
        <div
          ref={ref}
          style={{
            zIndex: 20,
            width: 132,
          }}
        >
          <ConnectionInfo iconName={getCommunicationWay()} value={name} />
        </div>
      </div>
    );
  }
);
