import { useState } from "react";
import OperationalSection from "./OperationalSectionLight";
import { List } from "immutable";
import {
  OperationalSectionLight as OperationalSectionTypeLight,
  OperationalSectionLightConfig,
} from "../../../types/operationalSection";
import { v4 as uuid } from "uuid";
import { useDrop } from "react-dnd/dist/hooks";
import { Grid, IconButton, TextField, Theme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles } from "tss-react/mui";
import OperationalDialogLight from "./OperationalDialogLight";
import OperationalSectionLight from "./OperationalSectionLight";
import useWindowDimensions from "../../../helpers/useWindowSize";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  operationalSection: {
    margin: 2,
    height: "auto",
    minHeight: 50,
    backgroundColor: "brown",
  },
  operationalSectionSaveConfig: {
    width: 50,
    position: "fixed",
    top: 180,
    right: 30,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  resourcesArea: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    width: 300,
    backgroundColor: theme.palette.background.paper,
  },
  grid: {
    alignItems: "center",
  },
}));

export enum DropDirection {
  Right = "RIGHT",
  Center = "CENTER",
  Left = "LEFT",
}

export function getMaxElements(config: List<OperationalSectionTypeLight>) {
  var amountList = config.map((parent) =>
    parent.operationalSections.count() === 0
      ? 1
      : parent.operationalSections
          .map((x) => getCildrenAmount(x.operationalSections))
          .reduce((acc, val) => acc + val, 0)
  );
  var max = amountList.max() ?? 0;
  return max;
}
export function getCildrenAmount(list: List<OperationalSectionTypeLight>) {
  if (list.count() === 0) return 1;
  var count = 0;
  list.forEach((x) => {
    if (x.operationalSections.count() > 0)
      count = count + getCildrenAmount(x.operationalSections);
    else count = count + 1;
  });
  return count;
}

export const OperationalSectionsLight = () => {
  const { classes } = useStyles();
  const { height, width } = useWindowDimensions();
  const [operationalSectionConfig, setOperationalSectionConfig] =
    useState<OperationalSectionLightConfig>({
      name: undefined,
      start: new Date(Date.now()),
      end: new Date(Date.now()),
      config: List<OperationalSectionTypeLight>([]),
    } as OperationalSectionLightConfig);

  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [selectedOperationalSection, setSelectedOperationalSection] = useState<
    OperationalSectionTypeLight | undefined
  >(undefined);

  const openAdd = () => {
    setIsDialogOpened(true);
  };

  const closeAdd = () => {
    setSelectedOperationalSection(undefined);
    setIsDialogOpened(false);
  };

  const onEdit = (section: OperationalSectionTypeLight) => {
    setSelectedOperationalSection(section);
    setIsDialogOpened(true);
  };

  const onSave = (toAdd: OperationalSectionTypeLight) => {
    if (toAdd.id === "")
      addOperationalSectionToRoot(operationalSectionConfig.config, toAdd);
    if (toAdd.id !== "") updateOperationalSections(toAdd);

    setSelectedOperationalSection(undefined);
  };

  const updateOperationalSections = (item: OperationalSectionTypeLight) => {
    const updatedOperationalSections = updateOperationalSection(
      operationalSectionConfig.config,
      item
    );
    setOperationalSectionConfig({
      ...operationalSectionConfig,
      config: updatedOperationalSections,
    });
  };

  const updateOperationalSection = (
    operationalSections: List<OperationalSectionTypeLight>,
    item: OperationalSectionTypeLight
  ) => {
    const index = operationalSections.findIndex((x) => x.id === item.id);
    const match = operationalSections.find((x) => x.id === item.id);
    if (index !== -1 && match) {
      return operationalSections.splice(index, 1, {
        ...item,
      });
    }

    let updatedOperationalSections = List<OperationalSectionTypeLight>();

    operationalSections.forEach((operationalSection) => {
      const updatedOperationalSectionOperationalSections =
        updateOperationalSection(operationalSection.operationalSections, item);
      const lefts = updateOperationalSection(operationalSection.left, item);
      const rights = updateOperationalSection(operationalSection.right, item);

      updatedOperationalSections = updatedOperationalSections.push({
        ...operationalSection,
        operationalSections: updatedOperationalSectionOperationalSections,
        left: lefts,
        right: rights,
      });
    });
    return updatedOperationalSections;
  };

  const changeOperationalSectionOperationalSection = (
    isOver: boolean,
    operationalSectionId: string,
    item: OperationalSectionTypeLight,
    direction: DropDirection
  ) => {
    const updatedOperationalSections = getChangedOperationalSections(
      operationalSectionConfig.config,
      isOver,
      operationalSectionId,
      item,
      direction
    );
    setOperationalSectionConfig({
      ...operationalSectionConfig,
      config: updatedOperationalSections,
    });
  };

  const getChangedOperationalSections = (
    operationalSections: List<OperationalSectionTypeLight>,
    isOver: boolean,
    operationalSectionId: string,
    item: OperationalSectionTypeLight,
    direction: DropDirection
  ) => {
    // alten aus der Liste und den Seitenlisten entfernen
    const filteredOperationalSections =
      removeRootOperationalSectionFromSubSections(operationalSections, item);
    debugger;
    const res = getChanged(
      filteredOperationalSections,
      isOver,
      operationalSectionId,
      item,
      direction
    );
    return res;
  };

  const getChanged = (
    operationalSections: List<OperationalSectionTypeLight>,
    isOver: boolean,
    operationalSectionId: string,
    item: OperationalSectionTypeLight,
    direction: DropDirection
  ) => {
    let updatedOperationalSections = List<OperationalSectionTypeLight>();
    operationalSections.map((operationalSection) => {
      let updatedOperationalSectionOperationalSections =
        operationalSection.operationalSections;
      if (
        isOver &&
        operationalSection.id === operationalSectionId &&
        direction === DropDirection.Center
      )
        updatedOperationalSectionOperationalSections =
          updatedOperationalSectionOperationalSections.push({
            ...item,
            id: uuid(),
          });

      updatedOperationalSectionOperationalSections = getChanged(
        updatedOperationalSectionOperationalSections,
        isOver,
        operationalSectionId,
        item,
        direction
      );
      let lefts = operationalSection.left;
      if (
        isOver &&
        operationalSection.id === operationalSectionId &&
        direction === DropDirection.Left
      )
        lefts = lefts.push({
          ...item,
          id: uuid(),
        });
      lefts = getChanged(lefts, isOver, operationalSectionId, item, direction);

      let rights = operationalSection.right;
      if (
        isOver &&
        operationalSection.id === operationalSectionId &&
        direction === DropDirection.Right
      )
        rights = rights.push({
          ...item,
          id: uuid(),
        });
      rights = getChanged(
        rights,
        isOver,
        operationalSectionId,
        item,
        direction
      );

      updatedOperationalSections = updatedOperationalSections.push({
        ...operationalSection,
        operationalSections: updatedOperationalSectionOperationalSections,
        right: rights,
        left: lefts,
      });
    });
    return updatedOperationalSections;
  };

  const updateOperationalSectionIsColapsed = (
    isColapsed: boolean,
    operationalSectionId: string
  ) => {
    const updatedOperationalSections = changeOperationalSectionIsColapsed(
      operationalSectionConfig.config,
      operationalSectionId,
      isColapsed
    );
    setOperationalSectionConfig({
      ...operationalSectionConfig,
      config: updatedOperationalSections,
    });
  };

  const changeOperationalSectionIsColapsed = (
    operationalSections: List<OperationalSectionTypeLight>,
    operationalSectionId: string,
    isColapsed: boolean
  ) => {
    let updatedOperationalSections = List<OperationalSectionTypeLight>();
    operationalSections.forEach((operationalSection) => {
      let updatedOperationalSectionOperationalSections =
        operationalSection.operationalSections;
      let updatedIsColapsed =
        operationalSection.id === operationalSectionId
          ? isColapsed
          : operationalSection.isCollapsed;

      updatedOperationalSectionOperationalSections =
        changeOperationalSectionIsColapsed(
          updatedOperationalSectionOperationalSections,
          operationalSectionId,
          isColapsed
        );

      updatedOperationalSections = updatedOperationalSections.push({
        ...operationalSection,
        operationalSections: updatedOperationalSectionOperationalSections,
        isCollapsed: updatedIsColapsed,
      });
    });
    return updatedOperationalSections;
  };

  const removeOperationalSection = (item: OperationalSectionTypeLight) => {
    const res = removeRootOperationalSectionFromSubSections(
      operationalSectionConfig.config,
      item
    );
    setOperationalSectionConfig({
      ...operationalSectionConfig,
      config: res,
    });
  };

  const addOperationalSectionToRoot = (
    operationalSections: List<OperationalSectionTypeLight>,
    item: OperationalSectionTypeLight
  ) => {
    const newOperationalSections = operationalSections
      .filter((operationalSection) => operationalSection.id !== item.id)
      .push({ ...item, id: uuid() });

    const res = removeRootOperationalSectionFromSubSections(
      newOperationalSections,
      item
    );

    setOperationalSectionConfig({
      ...operationalSectionConfig,
      config: res,
    });
  };

  const removeRootOperationalSectionFromSubSections = (
    operationalSections: List<OperationalSectionTypeLight>,
    item: OperationalSectionTypeLight
  ) => {
    let updatedOperationalSections = List<OperationalSectionTypeLight>();
    operationalSections
      .filter((operationalSection) => operationalSection.id !== item.id)
      .forEach((operationalSection) => {
        let updatedOperationalSectionOperationalSections =
          operationalSection.operationalSections.filter(
            (operationalSection) => operationalSection.id !== item.id
          );
        updatedOperationalSectionOperationalSections =
          removeRootOperationalSectionFromSubSections(
            updatedOperationalSectionOperationalSections,
            item
          );

        let rights = operationalSection.right.filter(
          (operationalSection) => operationalSection.id !== item.id
        );
        rights = removeRootOperationalSectionFromSubSections(rights, item);

        let lefts = operationalSection.left.filter(
          (operationalSection) => operationalSection.id !== item.id
        );
        lefts = removeRootOperationalSectionFromSubSections(lefts, item);

        updatedOperationalSections = updatedOperationalSections.push({
          ...operationalSection,
          operationalSections: updatedOperationalSectionOperationalSections,
          left: lefts,
          right: rights,
        });
      });
    return updatedOperationalSections;
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...operationalSectionConfig,
      name: e.target.value,
    } as OperationalSectionLightConfig;
    setOperationalSectionConfig(newState);
  };

  const [{ isOver, item }, dropRef] = useDrop({
    accept: "operationalSection",
    drop: (item: OperationalSectionTypeLight) => {
      if (isOver) {
        addOperationalSectionToRoot(operationalSectionConfig.config, item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      item: monitor.getItem(),
    }),
  });

  const maxElements = getMaxElements(operationalSectionConfig.config);
  const widthPerElement = (width ?? 1) / (maxElements ?? 1);

  console.log(width);
  console.log(widthPerElement);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <TextField
            label="Name"
            style={{ margin: 8, width: "100%" }}
            size="small"
            autoComplete="off"
            id="name"
            value={operationalSectionConfig.name ?? ""}
            onChange={onChangeName}
          />
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <IconButton color="primary" size="small" onClick={openAdd}>
            <AddCircleOutlineIcon fontSize="medium" />
          </IconButton>
        </Grid>
      </Grid>

      <div ref={dropRef}>
        {operationalSectionConfig.config.map((operationalSection) => (
          <OperationalSectionLight
            widthPerElement={widthPerElement}
            updateOperationalSectionIsColapsed={
              updateOperationalSectionIsColapsed
            }
            level={1}
            key={operationalSection.id}
            operationalSection={operationalSection}
            onEdit={onEdit}
            changeOperationalSectionOperationalSections={
              changeOperationalSectionOperationalSection
            }
            removeOperationalSection={removeOperationalSection}
          />
        ))}
        {isOver && (
          <OperationalSection
            updateOperationalSectionIsColapsed={
              updateOperationalSectionIsColapsed
            }
            level={1}
            key={`${item.id}_preview`}
            operationalSection={item}
            isPreview
            widthPerElement={widthPerElement}
            onEdit={onEdit}
            changeOperationalSectionOperationalSections={
              changeOperationalSectionOperationalSection
            }
            removeOperationalSection={removeOperationalSection}
          />
        )}
      </div>
      {isDialogOpened && (
        <OperationalDialogLight
          isOpen={isDialogOpened}
          selectedOperationalSection={selectedOperationalSection}
          close={closeAdd}
          save={onSave}
        />
      )}
    </div>
  );
};
