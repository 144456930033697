import { IconButton, Popover, Typography } from "@mui/material";
import { OperationalSectionLight as OperationalSectionTypeLight } from "../../../types/operationalSection";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { TacticalCommunicationSigns } from "../../../types/tacticalSigns";
import ConnectionInfo from "./ConnectionInfo";
import SectionCardIcon from "./SectionCardIcon";

interface Props {
  widthPerElement: number;
  operationalSection: OperationalSectionTypeLight;
  onChangeIsCollapsed: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRemove: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function SectionInfo({
  widthPerElement,
  operationalSection,
  onChangeIsCollapsed,
  onEdit,
  onRemove,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const togglePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const onChangeIsCollapsedPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleClose();
    onChangeIsCollapsed(event);
  };
  const onEditPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose();
    onEdit(event);
  };
  const onRemovePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose();
    onRemove(event);
  };

  const actionButtons = [
    <IconButton size="small" onClick={onChangeIsCollapsedPopover}>
      {operationalSection.isCollapsed ? (
        <ArrowDropDownIcon fontSize="small" />
      ) : (
        <ArrowDropUpIcon fontSize="small" />
      )}
    </IconButton>,
    <IconButton aria-label="delete" size="small" onClick={onEditPopover}>
      <EditIcon fontSize="small" />
    </IconButton>,
    <IconButton aria-label="delete" size="small" onClick={onRemovePopover}>
      <DeleteForeverIcon fontSize="small" />
    </IconButton>,
  ];

  return (
    <div
      style={{
        display: "block",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SectionCardIcon name={operationalSection.tacticalSign} />
        {widthPerElement > 150 && (
          <Typography
            variant="subtitle2"
            color="text"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {operationalSection.name}
          </Typography>
        )}
        <>
          <IconButton size="small" onClick={togglePopover}>
            <MenuOutlinedIcon fontSize="small" />
          </IconButton>
          <Popover
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {actionButtons.map((x) => x)}
          </Popover>
        </>
      </div>
      {widthPerElement <= 150 && (
        <Typography
          variant="subtitle2"
          color="text"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {operationalSection.name}
        </Typography>
      )}
      <ConnectionInfo
        iconName={undefined}
        value={operationalSection.callSign}
      />
      <ConnectionInfo
        iconName={TacticalCommunicationSigns.ConnectionTelLetters}
        value={operationalSection.tel}
      />
      <ConnectionInfo
        iconName={TacticalCommunicationSigns.ConnectionMobileLetters}
        value={operationalSection.mobile}
      />
      <ConnectionInfo
        iconName={TacticalCommunicationSigns.ConnectionFaxLetters}
        value={operationalSection.fax}
      />
    </div>
  );
}
