import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { List } from "immutable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OperationalSectionLight } from "../../../types/operationalSection";
import { TacticalSigns } from "../../../types/tacticalSigns";
import TacticalSignInput from "../../inputs/TacticalSignInput";
import FormInput from "../../layout/formpage/FormInput";
import FormSection from "../../layout/formpage/FormSection";

interface ImportDialogLightProps {
  isOpen: boolean;
  selectedOperationalSection?: OperationalSectionLight;
  close: () => void;
  save: (state: OperationalSectionLight) => void;
}

export default function OperationalDialogLight({
  isOpen,
  selectedOperationalSection,
  close,
  save,
}: ImportDialogLightProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState<OperationalSectionLight>(
    selectedOperationalSection ?? {
      id: "",
      name: "",
      positionCount: 0,
      isCollapsed: false,
      operationalSections: List<OperationalSectionLight>(),
      left: List<OperationalSectionLight>(),
      right: List<OperationalSectionLight>(),
    }
  );

  const onClose = () => {
    setState({
      id: "",
      name: "",
      positionCount: 0,
      isCollapsed: false,
      operationalSections: List<OperationalSectionLight>(),
      left: List<OperationalSectionLight>(),
      right: List<OperationalSectionLight>(),
    });
    close();
  };

  const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    save(state);
    onClose();
  };

  const onChangeTacticalSign = (value?: TacticalSigns) => {
    const newState = {
      ...state,
      tacticalSign: value,
    } as OperationalSectionLight;
    setState(newState);
  };
  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      name: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };
  const onChangeCallSign = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      callSign: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };
  const onChangeTel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      tel: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };
  const onChangeMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      mobile: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };
  const onChangeFax = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      fax: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };
  const onChangeRadioGroupTmo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      radioGroupTmo: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };

  const onChangeRadioGroupDmo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...state,
      radioGroupDmo: e.target.value,
    } as OperationalSectionLight;
    setState(newState);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={!fullScreen ? { padding: "16px 24px 10px 24px" } : undefined}
    >
      <DialogTitle id="form-dialog-title">{`Einsatzbereich ${state.name} ${
        !!selectedOperationalSection ? "bearbeiten" : "erstellen"
      }`}</DialogTitle>
      <DialogContent style={{ padding: "2px 24px 24px 24px" }}>
        <FormSection>
          <FormInput label="Taktisches Zeichen">
            <TacticalSignInput
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.tacticalSign}
              onChange={onChangeTacticalSign}
            />
          </FormInput>
          <FormInput label="Name">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="name"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.name ?? ""}
              onChange={onChangeName}
            />
          </FormInput>
          <FormInput label="Funkrufname">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="funkrufname"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.callSign ?? ""}
              onChange={onChangeCallSign}
            />
          </FormInput>
          <FormInput label="Telefonnummer">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="telefonnummer"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.tel ?? ""}
              onChange={onChangeTel}
            />
          </FormInput>
          <FormInput label="Mobil">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="mobile"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.mobile ?? ""}
              onChange={onChangeMobile}
            />
          </FormInput>
          <FormInput label="Fax">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="fax"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.fax ?? ""}
              onChange={onChangeFax}
            />
          </FormInput>
          <FormInput label="Funkrufgruppe TMO">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="radioGroup"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.radioGroupTmo ?? ""}
              onChange={onChangeRadioGroupTmo}
            />
          </FormInput>
          <FormInput label="Funkrufgruppe DMO">
            <TextField
              size="small"
              InputProps={{
                style: {
                  color: theme.palette.getContrastText(
                    theme.palette.background.default
                  ),
                },
              }}
              autoComplete="off"
              id="radioGroup"
              style={{
                margin: theme.spacing(1),
                minWidth: 300,
                display: "flex",
              }}
              value={state.radioGroupDmo ?? ""}
              onChange={onChangeRadioGroupDmo}
            />
          </FormInput>
        </FormSection>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Schließen
        </Button>
        <Button onClick={onSave} color="success">
          {!!selectedOperationalSection ? "übernehmen" : "hinzufügen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
